import React, { useEffect, useRef, useState } from 'react';
import '../Visiting_Cards/Visitingcard.css';
import leftimg from '../../assets/img/wave.png'
import { PDFExport } from '@progress/kendo-react-pdf';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5'

function Visitingcard(props) {


    return (
        <>
            <div id="visiting_card_container">
                <PDFExport ref={props.card_front} fileName='VisitingCard.pdf' margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>
                    <div className={`visiting_card_content ${props.isFlip ? `flipped` : ""} `} onClick={props.handleFlip}>


                        <div className='card_section' style={{ backgroundImage: `url(${leftimg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "100%", display: "flex", flexDirection: "column", padding: "0px" }}>
                            <div className='card_head'>
                                <div className='brand_logo' style={{ backgroundImage: `url(${props?.data.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                                </div>
                                <h4>{props?.data.company}</h4>
                            </div>
                            <div style={{ display: "flex", height: "100%", padding: "0.3rem" }}>

                                <div className='card_left_content' >
                                    <div className='card_left_data'>
                                        <h1>{props?.data.name}</h1>
                                        <p style={{ fontSize: "0.8rem", textAlign: "center" }}>{props?.data.designation}</p>
                                    </div>

                                </div>
                                <div className='card_right_content' >
                                    <a>
                                        <div className='logo_box'>
                                            <BsTelephone style={{color:"blue"}} />
                                        </div>
                                        <div className='right_item'>{props.data.jsonPhones?.map((item) => item?.value)}</div>
                                    </a>
                                    <a>
                                        <div className='logo_box'>
                                            <AiOutlineMail style={{color:"blue"}}/>
                                        </div>
                                        <div className='right_item'>{props.data.jsonEmails?.map((item) => item?.value)[0]}</div>
                                    </a>
                                    <a>
                                        <div className='logo_box'>
                                            <IoLocationOutline style={{color:"blue"}}/>
                                        </div>
                                        <div className='right_item'>{props?.data.location}</div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='visiting_card_back' onClick={props.handleFlip} style={{ backgroundImage: `url(${leftimg})` }}>
                        <div className={`visiting_card_back_content`}>
                            <div className='brand_logo' style={{ backgroundImage: `url(${props?.data.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "50px", width: "50px" }}></div>
                            <h1>{props?.data.company}</h1>
                        </div>
                    </div>
                </PDFExport>
                <div className='print_btn' onClick={props.generatePDF}>
                    <button>Print</button>
                </div>
            </div>
        </>
    );
}

export default Visitingcard;