import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, Route, Router, Routes, } from 'react-router-dom';
import CardPreview from './cardPreview';
import Error404 from './404';


function App() {
  return (
    <BrowserRouter>

      <Routes>
        <Route path="/" element={<Navigate to="/preview-card" replace />} />
        <Route path='/preview-card/:cardId' element={<CardPreview />} />
        <Route path='*' element={<Error404 />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
