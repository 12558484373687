import React from 'react';
import FrontImg from '../../assets/img/blob-scene.svg'
import BackImg from "../../assets/img/blob-scene-back.svg";
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5'
import { PDFExport, PDFMargin } from '@progress/kendo-react-pdf';
function Visitingcard2(props) {
    return (
        <>
            <div id="visiting_card_container" style={{ color: "black" }}>
                <PDFExport ref={props.card_front} fileName='VisitingCard.pdf' margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>

                    <div className={`visiting_card_content ${props.isFlip ? `flipped` : ""} `} onClick={props.handleFlip}>

                        <div className='card_section' style={{ backgroundImage: `url(${FrontImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: '100%', color: "black" }}>

                            <div className='card_right_content' style={{ border: "none", paddingTop: '4rem', color: "black" }}>
                                <a>
                                    <div className='logo_box' style={{ border: "1px solid green" }}>
                                        <BsTelephone style={{ color: "green" }} />
                                    </div>
                                    <div className='right_item'>{props.data.jsonPhones?.map((item) => item?.value)}</div>
                                </a>
                                <a>
                                    <div className='logo_box' style={{ border: "1px solid green" }}>
                                        <AiOutlineMail style={{ color: "green" }} />
                                    </div>
                                    <div className='right_item'>{props.data.jsonEmails?.map((item) => item?.value)[0]}</div>
                                </a>
                                <a>
                                    <div className='logo_box' style={{ border: "1px solid green" }}>
                                        <IoLocationOutline style={{ color: "green" }} />
                                    </div>
                                    <div className='right_item'>{props.data.location}</div>
                                </a>
                            </div>

                            <div className='card_left_content' style={{ paddingBottom: "5rem" }}>
                                <div className='card_left_data' >
                                    <h1>{props?.data.name}</h1>
                                    <p style={{ fontSize: "0.8rem", textAlign: "center" }}>{props?.data.designation}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='visiting_card_back' onClick={props.handleFlip} style={{ backgroundImage: `url(${BackImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        <div className={`visiting_card_back_content`}>
                            <div className='brand_logo' style={{ backgroundImage: `url(${props?.data.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "50px", width: "50px" }}></div>
                        </div>
                    </div>

                </PDFExport>
                <div className='print_btn' onClick={props.generatePDF}>
                    <button>Print</button>
                </div>
            </div>
        </>
    );
}

export default Visitingcard2;