import axios from "axios";


import { useEffect, useLayoutEffect, useRef, useState } from "react";
import JsxParser from "react-jsx-parser";
import { useParams } from "react-router-dom";
import Visitingcard from "./component/Visiting_Cards/Visitingcard";
import Visitingcard2 from "./component/Visiting_Cards/Visitingcard2";
import Visitingcard3 from "./component/Visiting_Cards/Visitingcard3";
import Visitingcard4 from "./component/Visiting_Cards/Visitingcard4";
import Visitingcard5 from "./component/Visiting_Cards/Visitingcard5";
import Visitingcard6 from "./component/Visiting_Cards/Visitingcard6";

const BASE_URL = "https://ecardscanner.onrender.com/api/";
export const HEADER_IN_JSON = {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
};

const getCardData = async (cardId) => {
    const respose = await axios(BASE_URL + "card/getById/" + cardId, { validateStatus() { return true } });
    const data = respose.data;
    return data;
};

const getTemplateData = async (templateId) => {
    const respose = await axios(BASE_URL + "template/getById/" + templateId, { validateStatus() { return true } });
    const data = respose.data;
    return data;
};

const GET_LOCATION = "https://ipapi.co/json";

const getLocationByIP = async () => {
    const respose = await axios(GET_LOCATION);
    const data = respose.data;
    return data;
};

export default function CardPreview() {
    const { cardId } = useParams();
    const [cardData, setCardData] = useState([]);
    const [template, setTemplate] = useState([]);
    const [jsonEmails, setJsonEmails] = useState([]);
    const [jsonSocialMediaLinks, setJsonSocialMediaLinks] = useState([]);
    const [jsonPhones, setPhones] = useState([]);
    const [logoImage, setLogoImage] = useState("")
    const [profileImage, setProfileImage] = useState("");
    const [error, setError] = useState(false)
    const dataFetchedRef = useRef(false);
    const [isLoading, setLoading] = useState(true)
    const handleGetCardData = (cardId) => {
        getCardData(cardId).then((response) => {
            if (response.err === false) {
                setPhones(JSON.parse(response?.card?.phones));
                setCardData(response?.card);
                setJsonEmails(JSON.parse(response?.card?.emails));
                setError(false)
                setLoading(false)
                setJsonSocialMediaLinks(JSON.parse(response?.card?.socialLink));
                if (response?.card?.templateId) {
                    handleGetTemplate(response?.card?.templateId);
                }
            } else {
                setLoading(false)
                setError(true)
            }
        }).catch((err) => console.log(err));
    };

    const handleGetTemplate = (templateId) => {
        getTemplateData(templateId)
            .then((response) => {
                if (response.err === false) {
                    setTemplate(response?.template);
                    handleWriteCss(response?.template?.css);
                    getLatLng();
                }
            })
            .catch((err) => console.log(err));
    };

    const handleWriteCss = (css) => {
        let head = document.head || document.getElementsByTagName("head")[0];
        let style = document.createElement("style");

        head.appendChild(style);

        style.type = "text/css";
        if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        handleGetCardData(cardId);
    }, [cardData]);

    useLayoutEffect(() => {
        if (template?.html) {
            if (cardData?.isDownload) {
                generateVcard();
            }
            document.querySelector("button").addEventListener("click", function () {
                generateVcard();
            });
        }
    }, [template]);

    const socialMapping = (array) => {
        let data = ""
        for (let index = 0; index < array.length; index++) {
            data += `X-SOCIALPROFILE;type=${array[index]?.title};:${array[index]?.link}\n`

        }
        return data

    }
    const emailMapping = (array) => {
        let data = ""
        for (let index = 0; index < array.length; index++) {
            data += `EMAIL;type=${array[index]?.type};:${array[index]?.value}\n`

        }
        return data

    }
    const phoneMapping = (array) => {
        let data = ""
        for (let index = 0; index < array.length; index++) {
            data += `TEL;type=${array[index]?.type};:${array[index]?.value}\n`
        }
        return data

    }


    const generateUrlIntoBase64 = async (url) => await fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            return new Promise((res) => {
                reader.onloadend = () => {
                    res(getBase64StringFromDataURL(reader.result));
                }
            })
        })

    const getBase64StringFromDataURL = (dataURL) =>
        dataURL.replace('data:', '').replace(/^.+,/, '');
    function get_url_extension(url) {
        if (url.split(/[#?]/)[0].split('.').pop().trim() === 'jpg') {
            return "JPEG"
        } else {
            return url.split(/[#?]/)[0].split('.').pop().trim();
        }


    }

    const convertBase64Logo = async (url) => {
        await generateUrlIntoBase64(url).then((res) => {
            return setLogoImage(`LOGO;ENCODING=b;TYPE=${get_url_extension(url).toUpperCase().toString()}:${res.replace(/^data:image\/[a-z]+;base64,/, "")}`);
        }
        ).catch(err => console.log(err))
    }

    const convertBase64profilePhoto = async (url) => {
        await generateUrlIntoBase64(url).then((res) => {
            return setProfileImage(`PHOTO;ENCODING=b;TYPE=${get_url_extension(url).toUpperCase().toString()}:${res.replace(/^data:image\/[a-z]+;base64,/, "")}`);
        }
        ).catch(err => console.log(err))
    }

    useEffect(() => {
        if (cardData?.logo) {
            convertBase64Logo(cardData?.logo?.replace('http://', 'https://'))
        }
        if (cardData?.profilePhoto) {
            convertBase64profilePhoto(cardData?.profilePhoto?.replace('http://', 'https://'))
        }


        return () => {

        }
    }, [cardData])




    const generateVcard = () => {
        console.log(profileImage);
        let vCard = `BEGIN:VCARD    
VERSION:3.0
N;CHARSET=UTF-8:${cardData?.firstName} ${cardData?.lastName} 
FN;CHARSET=UTF-8:${cardData?.prefix} ${cardData?.firstName} ${cardData.lastName};${cardData?.suffix}
NICKNAME;CHARSET=UTF-8:${cardData?.firstName} ${cardData?.lastName}
${logoImage}
${profileImage}
${jsonEmails.map(
            (item, index) =>
                `EMAIL;CHARSET=UTF-8;TYPE=${item?.type},INTERNET:${item?.value}`
        )}
${jsonPhones?.map(
            (item, index) => `TEL;TYPE=${item?.type},CELL:${item?.value}`
        )}
LABEL;CHARSET=UTF-8;TYPE=HOME:${cardData?.location}
ADR;CHARSET=UTF-8;TYPE=HOME:;;${cardData?.location}
ROLE;CHARSET=UTF-8:${cardData?.designation}
ORG;CHARSET=UTF-8:${cardData?.company}
${emailMapping(jsonEmails)}
${phoneMapping(jsonPhones)}
${socialMapping(jsonSocialMediaLinks)}
REV:2023-05-18T08:30:47.500Z
END:VCARD`;
        console.log(vCard);
        downloadToFile(vCard, "vcard.vcf", "text/vcard");
    };

    function downloadToFile(content, filename, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    const convertJson = (json) => {
        return json && JSON.parse(json);
    };

    const getLatLng = () => {
        getLocationByIP().then((response) => {
            console.log(response);
            if (response) {
                increaseCount(response);
            }
        });
    };

    const increaseCount = (location) => {
        const body = {
            cardId: cardId,
            longitude: location?.latitude,
            latitude: location?.longitude,
            city: location?.city,
            region: location?.country_name,
            state: location?.region
        };
        axios.post(`${BASE_URL}cardView/addView/`, body, {
            headers: HEADER_IN_JSON,
            validateStatus() {
                return true;
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((err) => console.log(err));
    };

    // =====================Visitingcard====================================
    
    const card_front = useRef([]);

    const generatePDF = (index) => {
        if (card_front.current[index]) {
            card_front.current[index].save();
        }
    };

    // ===============================Flip-card===============================================
    const [isFlip, setIsFlip] = useState([false, false, false, false, false, false]);
    const data = { name: cardData?.firstName + " " + cardData?.lastName,  logo: cardData?.logo,designation: cardData?.designation, company: cardData?.company, logo: cardData?.logo, jsonEmails: jsonEmails, jsonPhones: jsonPhones, location: cardData?.location, isFlip: isFlip };

    const handleFlip = (index) => {
        const newFlipState = [...isFlip];
        newFlipState[index] = !newFlipState[index];
        setIsFlip(newFlipState);
    };

    const cards = [
        <Visitingcard data={data} isFlip={isFlip[0]} handleFlip={() => handleFlip(0)} card_front={(element)=>card_front.current[0]=element} generatePDF={()=>generatePDF(0)}/>,
        <Visitingcard2 data={data} isFlip={isFlip[1]} handleFlip={() => handleFlip(1)} card_front={(element)=>card_front.current[1]=element} generatePDF={()=>generatePDF(1)}/>,
        <Visitingcard3 data={data} isFlip={isFlip[2]} handleFlip={() => handleFlip(2)} card_front={(element)=>card_front.current[2]=element} generatePDF={()=>generatePDF(2)}/>,
        <Visitingcard4 data={data} isFlip={isFlip[3]} handleFlip={() => handleFlip(3)} card_front={(element)=>card_front.current[3]=element} generatePDF={()=>generatePDF(3)}/>,
        <Visitingcard5 data={data} isFlip={isFlip[4]} handleFlip={() => handleFlip(4)} card_front={(element)=>card_front.current[4]=element} generatePDF={()=>generatePDF(4)}/>,
        <Visitingcard6 data={data} isFlip={isFlip[5]} handleFlip={() => handleFlip(5)} card_front={(element)=>card_front.current[5]=element} generatePDF={()=>generatePDF(5)}/>,
    ];
    console.log(card_front);
    return (
        <>
            <div className="row">
                <div className="col-5 mt-sm-2">

                    <JsxParser
                        bindings={{
                            socialMediaLinks: convertJson(cardData?.socialLink),
                            customLinks: convertJson(cardData?.customFields),
                            emails: convertJson(cardData?.emails),
                            phones: convertJson(cardData?.phones),
                            website: convertJson(cardData?.webs),
                            name: cardData?.firstName + " " + cardData?.lastName,
                            designation: cardData?.designation,
                            userImage: cardData?.profilePhoto,
                            logo: cardData?.logo,
                            location: cardData?.location,
                            prefix: cardData?.prefix,
                            suffix: cardData?.suffix,
                            department: cardData?.department,
                            pronouns: cardData?.pronouns,
                            company: cardData?.company,
                            summary: cardData?.summery,
                            primaryColor: cardData?.primaryColor,
                            profileTextColor: cardData?.profileTextColor,
                            secondaryColor: cardData?.secondaryColor,
                            titleFontSize: cardData?.titleFontSize,

                            fontFamily: cardData?.fontFamily,
                        }}
                        jsx={template?.html}
                    />
                </div>
                <div className="col-7 mt-sm-5">
                    <h3>Visiting Cards</h3>
                    <div className="visiting_cards" style={{ display: "flex", gap: "1rem", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-start", marginBottom: "2rem" }}>
                        {
                            cards.map((card, index) => <>
                                {card}
                            </>)
                        }
                    </div>
                </div>
            </div>


        </>
    );
}


