import React from 'react';

import frontImg from '../../assets/img/polygon-front.svg'
import backimg from '../../assets/img/polygon-back.svg'

import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { IoLocation } from 'react-icons/io5'
import { PDFExport } from '@progress/kendo-react-pdf';
function Visitingcard5(props) {
    return (
        <>
            <div id="visiting_card_container">
                <PDFExport ref={props.card_front} fileName='VisitingCard.pdf' margin={{ top: 10, left: 10, right: 10, bottom: 10 }} keepTogether='.visiting_card_back'>
                    <div className={`visiting_card_content ${props.isFlip ? `flipped` : ""} `} onClick={props.handleFlip}>


                        <div className='card_section' style={{ backgroundImage: `url(${frontImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: '100%', padding: '0px' }}>
                            <div className='card_left_content' >
                                <div className='card_left_data'>
                                    <h1>{props.data.name}</h1>
                                    <p style={{ fontSize: "0.8rem", textAlign: "center" }}>{props.data.designation}</p>
                                </div>

                            </div>
                            <div className='card_right_content' style={{ border: "none", color: "black", padding: '0px' }}>
                                <a style={{ backgroundColor: "white", borderRadius: '10px 0% 0% 10px' }}>
                                    <div className='logo_box' style={{ borderRadius: "50%" }} >
                                        <BsFillTelephoneFill style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props.data.jsonPhones?.map((item) => item?.value)}</div>
                                </a>
                                <a style={{ backgroundColor: "white", borderRadius: '10px 0% 0% 10px' }}>
                                    <div className='logo_box' style={{ borderRadius: "50%" }}>
                                        <MdEmail style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props.data.jsonEmails?.map((item) => item?.value)[0]}</div>
                                </a>
                                <a style={{ backgroundColor: "white", borderRadius: '10px 0% 0% 10px' }}>
                                    <div className='logo_box' style={{ borderRadius: "50%" }}>
                                        <IoLocation style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props?.data.location}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='visiting_card_back' onClick={props.handleFlip} style={{ backgroundImage: `url(${backimg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                        <div className={`visiting_card_back_content`}>
                            <div className='brand_logo' style={{ backgroundImage: `url(${props?.data.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "50px", width: "50px" }}></div>
                            <h1>{props.data.company}</h1>
                        </div>
                    </div>
                </PDFExport>
                <div className='print_btn' onClick={props.generatePDF}>
                    <button>Print</button>
                </div>
            </div>
        </>
    );
}

export default Visitingcard5;