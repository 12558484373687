import React from 'react';


import { BsFillTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { IoLocation } from 'react-icons/io5'
import { PDFExport } from '@progress/kendo-react-pdf';
function Visitingcard3(props) {
    return (
        <>
            <div id="visiting_card_container">
                <PDFExport ref={props.card_front} fileName='VisitingCard.pdf' margin={{ top: 10, left: 10, right: 10, bottom: 10 }}>
                    <div className={`visiting_card_content ${props.isFlip ? `flipped` : ""} `} onClick={props.handleFlip} style={{ backgroundColor: "white", fontFamily: "sans-serif" }}>

                        <div className='card_section' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', backgroundColor: 'transparent', padding: "0px", border: "1px solid #d0d0d0" }}>

                            <div className='card_right_content' style={{ backgroundColor: '#001829', paddingLeft: '0.5rem', border: "none", borderRadius: "0px 5% 5% 0%", height: '80%', width: '60%' }}>
                                <a>
                                    <div className='logo_box' style={{ borderRadius: "50%" }}>
                                        <BsFillTelephoneFill style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props.data.jsonPhones?.map((item) => item?.value)}</div>
                                </a>
                                <a>
                                    <div className='logo_box' style={{ borderRadius: "50%" }}>
                                        <MdEmail style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props.data.jsonEmails?.map((item) => item?.value)[0]}</div>
                                </a>
                                <a>
                                    <div className='logo_box' style={{ borderRadius: "50%" }}>
                                        <IoLocation style={{color:"blue"}}/>
                                    </div>
                                    <div className='right_item'>{props?.data.location}</div>
                                </a>
                            </div>
                            <div className='name_designation' style={{ position: "absolute", left: '62%', color: 'black' }}>
                                <h1 style={{ borderBottom: "1px solid black", paddingBottom: '0.3rem' }}>{props?.data.name}<br/> <span style={{ fontSize: "0.9rem" }}>{props.data.designation}</span></h1>
                            </div>
                        </div>

                    </div>
                    <div className='visiting_card_back' onClick={props.handleFlip} style={{ backgroundColor: "#001122", border: "1px solid gray" }}>
                        <div className={`visiting_card_back_content`}>
                            <div className='brand_logo' style={{ backgroundImage: `url(${props?.data.logo})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "50px", width: "50px" }}></div>
                            <h1>{props?.data.company}</h1>
                        </div>
                    </div>
                </PDFExport>
                <div className='print_btn' onClick={props.generatePDF}>
                    <button>Print</button>
                </div>
            </div>
        </>
    );
}

export default Visitingcard3;