import React from 'react'
import styles from './error.module.css'
export default function Error404() {
    return (
        <div className={styles.container}>
            <div className={styles.gif}>
                <img src="https://i.postimg.cc/2yrFyxKv/giphy.gif" alt="gif_ing" />
            </div>
            <div className={styles.content}>
                <h1 className={styles.mainHeading}>This card is gone.</h1>
                <p className={styles.paragraph}>
                    ...maybe the page you're looking for is not found or never existed.
                </p>
                <a href="https://cardconnect.ai/" target="blank">
                    <button className={styles.button}>Go to home<i className="far fa-hand-point-right" /></button>
                </a>
            </div>
        </div>

    )
}
